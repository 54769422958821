/**
 * Custom Site Functionality
 * Author - Edge Marketing
 *
 * Version - 1.2
 */

$(document).ready(function(){
    new Swiper ('.swiper-container', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        pagination: '.swiper-pagination',
        paginationClickable: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        effect: 'fade',
        autoplay: 4000,
        autoplayDisableOnInteraction: false,
        speed: 700,
        lazyLoading: true,
        lazyLoadingInPrevNext: true,
        lazyLoadingInPrevNextAmount: 1
    });

    $('.clear-height').css("height","auto");

    $("img.img-lightbox").click(function () {
        $.fancybox.open('<img class="img-fluid img-lightbox" src="' + this.src + '">');
    });

    $('.scroll-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });

    $('.gallery-hover').each(function() {
        var $this = $(this);
        var $caption = $this.find('.hover-caption');
        $this.hover(function() {
            $caption.stop().fadeIn(200);
        }, function() {
            $caption.stop().fadeOut(200);
        });
    });

    $("form").submit(function() {
        swal({
            title: "Processing...",
            icon: "warning",
            buttons: {
                confirm: {
                    closeModal: false
                },
            },
            closeOnClickOutside: false,
            closeOnEsc: false,
        });
    });
});